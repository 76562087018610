import {Link} from "react-router-dom";
import {appUrl, routesData} from "../index";
import {appImg} from "../config/config";
import {useEffect, useState} from "react";
import {Newsletter} from "./index";

const PageWrapper = ({children}) => {
    const [currentPage , setCurrentPage] = useState('/address')
    const [isOpen,setIsOpen] = useState(false)


    const handleMenu = () => {

    }

    useEffect(() => {
        let x = window.location.pathname
        setCurrentPage(x)
        console.log(x)

    },[])

    return (
        <>
            <header id="header" className="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light navbar-show-hide navbar-scrolled">
                <div className="container">
                    <nav className="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
                        <Link className="navbar-brand" to={appUrl.home} aria-label="Front">
                            <img className="navbar-brand-logo" src={appImg.logo} alt="Logo" height={85} width={120} />
                        </Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                                aria-label="Toggle navigation" onClick={()=>setIsOpen(!isOpen)}>
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNavDropdown">
                            <div className="navbar-absolute-top-scroller">
                                <ul className="navbar-nav">

                                    {routesData?.map((item,i=0) =>
                                        <li className=" nav-item" key={i+1}>
                                            <Link id="portfolioMegaMenu" className={`${currentPage===item.url?'active':''} hs-mega-menu-invoker nav-link`} to={item.url} role="button">{item.name}</Link>
                                        </li> )}




                                    {/* Button */}
                                    <li className="nav-item">
                                        <Link className="btn btn-primary btn-transition" to={appUrl.sign_up} >Sign up </Link>
                                    </li>
                                    {/* End Button */}
                                </ul>

                            </div>
                        </div>

                    </nav>
                </div>
            </header>



            <main className="container-fluid">
                <div className={'mt-5'} >


                    {children}
                </div>

            </main>

           <Newsletter/>
        </>
    )
}

export  default  PageWrapper