const CircularProgress = ({text,val,color,right,left_,color_2}) => {
    return(
        <div className="col-sm-6 col-lg-3 mb-7 mb-lg-4">
            {/* Circle Chart */}
            <div className="circles-chart">
                <div className="js-circle" id="circle-778379388094504">
                    <div className="circles-wrap" style={{position: 'relative', display: 'inline-block'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={200} height={200}>
                            <path fill="transparent" stroke={color_2} strokeWidth={8} d={right}
                                  className="circles-maxValueStroke" />

                            <path fill="transparent" stroke={color} strokeWidth={8} d={left_}
                                  className="circles-valueStroke" strokeLinecap="round" strokeMiterlimit={10} />
                        </svg>
                        <div className="circles-chart-content"
                             style={{position: 'absolute', textAlign: 'center', width: '100%', fontSize: '24px', height: 'auto', lineHeight: 'normal', fontWeight: 500, color: color}}>
                            {val}
                            <div style={{marginTop: '5px', marginBottom: '5px'}} />
                            <div style={{fontWeight: 400, fontSize: '13px', color: '#77838f'}}>{text}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Circle Chart */}
        </div>
    )
}

export default CircularProgress