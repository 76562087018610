import {PageWrapper} from "../layouts";

const SignUp = () => {
    return (
        <PageWrapper>
            <></>
        </PageWrapper>
    )
}

export  default  SignUp