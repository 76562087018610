import {PageWrapper} from "./index";
import {appImg} from "../config/config";

const About = () => {
    return (
        <PageWrapper>
            <div id="aboutSection" className="container content-space-t-2 content-space-t-lg-2">


                <div className="container content-space-2 content-space-lg-1">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-6 mb-9 mb-lg-0">
                            <div className="mb-3">
                                <h2 className="h1">About CYSS</h2>
                            </div>
                            <p>  The Commonwealth Students Association (CSA) Commonwealth Youth Council (CYC) and Pan African
                                Youth Union aims have Commonwealth Youth and Students Summit for African Region to be hosted under the auspices of
                                Federal Ministry of Youth and Sports Development in Nigeria.</p>
                            <p> The summit which will be sponsored by the Federal Ministry of Youth and Sports Development
                                in Nigeria seeks to create an avenue where crucial conversations about strengthening and promoting the leadership capacity of youth and student leaders
                                in the Commonwealth can be initiated and advanced with actionable outcomes.</p>
                        </div>
                        {/* End Col */}
                        <div className="col-lg-5 col-xl-5">
                            {/* SVG Element */}
                            <div className="position-relative mx-auto" style={{maxWidth: '28rem', minHeight: '30rem'}}>
                                <figure className="position-absolute top-0 end-0 zi-2 me-10 aos-init aos-animate" data-aos="fade-up">
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 450 450" width={165} height={165}>
                                        <g>
                                            <defs>
                                                <path id="circleImgID2" d="M225,448.7L225,448.7C101.4,448.7,1.3,348.5,1.3,225l0,0C1.2,101.4,101.4,1.3,225,1.3l0,0
                                                    c123.6,0,223.7,100.2,223.7,223.7l0,0C448.7,348.6,348.5,448.7,225,448.7z" />
                                            </defs>
                                            <clipPath id="circleImgID1">
                                                <use xlinkHref="#circleImgID2" />
                                            </clipPath>
                                            <g clipPath="url(#circleImgID1)">
                                                <image width={450} height={450} xlinkHref={appImg.img_2}/>
                                            </g>
                                        </g>
                                    </svg>
                                </figure>
                                <figure className="position-absolute top-0 start-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={300}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 335.2 335.2" width={120} height={120}>
                                        <circle fill="none" stroke="#377dff" strokeWidth={75} cx="167.6" cy="167.6" r="130.1" />
                                    </svg>
                                </figure>
                                <figure className="d-none d-sm-block position-absolute top-0 start-0 mt-10 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 515 515" width={200} height={200}>
                                        <g>
                                            <defs>
                                                <path id="circleImgID4" d="M260,515h-5C114.2,515,0,400.8,0,260v-5C0,114.2,114.2,0,255,0h5c140.8,0,255,114.2,255,255v5
                C515,400.9,400.8,515,260,515z" />
                                            </defs>
                                            <clipPath id="circleImgID3">
                                                <use xlinkHref="#circleImgID4" />
                                            </clipPath>
                                            <g clipPath="url(#circleImgID3)">
                                                <image width={515} height={515} xlinkHref={appImg.img_1} transform="matrix(1 0 0 1 1.639390e-02 2.880859e-02)" />
                                            </g>
                                        </g>
                                    </svg>
                                </figure>
                                <figure className="position-absolute top-0 end-0 aos-init aos-animate" style={{marginTop: '11rem', marginRight: '13rem'}} data-aos="fade-up" data-aos-delay={250}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 67 67" width={25} height={25}>
                                        <circle fill="#00C9A7" cx="33.5" cy="33.5" r="33.5" />
                                    </svg>
                                </figure>
                                <figure className="position-absolute top-0 end-0 me-3 aos-init aos-animate" style={{marginTop: '8rem'}} data-aos="fade-up" data-aos-delay={350}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 141 141" width={50} height={50}>
                                        <circle fill="#FFC107" cx="70.5" cy="70.5" r="70.5" />
                                    </svg>
                                </figure>
                                <figure className="position-absolute bottom-0 end-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 770.4 770.4" width={280} height={280}>
                                        <g>
                                            <defs>
                                                <path id="circleImgID6" d="M385.2,770.4L385.2,770.4c212.7,0,385.2-172.5,385.2-385.2l0,0C770.4,172.5,597.9,0,385.2,0l0,0
                C172.5,0,0,172.5,0,385.2l0,0C0,597.9,172.4,770.4,385.2,770.4z" />
                                            </defs>
                                            <clipPath id="circleImgID5">
                                                <use xlinkHref="#circleImgID6" />
                                            </clipPath>
                                            <g clipPath="url(#circleImgID5)">
                                                <image width={900} height={900} xlinkHref={appImg.img_3} transform="matrix(1 0 0 1 -64.8123 -64.8055)" />
                                            </g>
                                        </g>
                                    </svg>
                                </figure>
                            </div>
                            {/* End SVG Element */}
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>

                {/**/}

                {/**/}
                <div className="container content-space-1 content-space-lg-1">
                    <div className="row justify-content-lg-center">
                        <div className="col-sm-4 col-lg-3 mb-7 mb-sm-0">
                            <div className="text-center">
                                <h2 className="display-4">21</h2>
                                <p className="small">African Countries</p>
                            </div>
                        </div>
                        {/* End Col */}
                        <div className="col-sm-4 col-lg-3 mb-1 mb-sm-0">
                            <div className="text-center">
                                <h2 className="display-4">10</h2>
                                <p className="small">Key Partners</p>
                            </div>
                        </div>
                        {/* End Col */}
                        <div className="col-sm-4 col-lg-3">
                            <div className="text-center">
                                <h2 className="display-4">100</h2>
                                <p className="small">Sponsors</p>
                            </div>
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>




                {/*Our Aim*/}
                <div className="container content-space-2 content-space-lg-2">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <img src={appImg.cyss} alt={''} />
                        </div>
                        {/* End Col */}
                        <div className="col-lg-6">
                            <h2>Our Aim</h2>
                            <p>
                                We aim to bring together youth and students’leaders in the higher education and youth development sector from Commonwealth
                                countries in order to promote networking opportunities, enable collaboration among student and youth organisations,
                                advocate for the rights and responsibilities of students and,
                                to foster a sustainable learning environment and promote youth development in l institutions within the Commonwealth.
                            </p>
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>
                {/**/}

                {/* Our Objectives */}
                <div className="w-lg-100 mx-auto mb-1 mb-sm-9">
                    <h2 className="h1 text-center">Our Objectives</h2>
                    <div className="container content-space-t-1">
                        <div className="row justify-content-lg-center">
                            <div className="col-md-6 col-lg-5 mb-3 mb-md-7">
                                {/* Icon Blocks */}
                                <div className="d-flex pe-lg-5 aos-init aos-animate" data-aos="fade-up">
                                    <div className="flex-shrink-0">
                              <span className="svg-icon text-primary">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z" fill="#035A4B" />
                                  <path opacity="0.3" d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z" fill="#035A4B" />
                                  <path d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z" fill="#035A4B" />
                                </svg>
                              </span>
                                    </div>
                                    <div className="flex-grow-1 ms-4">
                                        <h4>Host youth and students</h4>
                                        <p>
                                            To host youth and students’ leaders in Africa for the purpose of networking, capacity building and employment for young people
                                        </p>
                                    </div>
                                </div>
                                {/* End Icon Blocks */}
                            </div>
                            {/* End Col */}
                            <div className="col-md-6 col-lg-5 mb-3 mb-md-7">
                                {/* Icon Blocks */}
                                <div className="d-flex ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="flex-shrink-0">
                              <span className="svg-icon text-primary">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="#035A4B" />
                                  <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="#035A4B" />
                                </svg>
                              </span>
                                    </div>
                                    <div className="flex-grow-1 ms-4">
                                        <h4>Address challenges</h4>
                                        <p>To address challenges faced by youth and students and their representatives.</p>
                                    </div>
                                </div>
                                {/* End Icon Blocks */}
                            </div>
                            {/* End Col */}
                            <div className="w-100" />
                            <div className="col-md-6 col-lg-5 mb-3 mb-md-7 mb-lg-0">
                                {/* Icon Blocks */}
                                <div className="d-flex pe-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="flex-shrink-0">
                                  <span className="svg-icon text-primary">
                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4 4L11.6314 2.56911C11.875 2.52343 12.125 2.52343 12.3686 2.56911L20 4V11.9033C20 15.696 18.0462 19.2211 14.83 21.2313L12.53 22.6687C12.2057 22.8714 11.7943 22.8714 11.47 22.6687L9.17001 21.2313C5.95382 19.2211 4 15.696 4 11.9033L4 4Z" fill="#035A4B" />
                                      <path fillRule="evenodd" clipRule="evenodd" d="M11.175 14.75C10.9354 14.75 10.6958 14.6542 10.5042 14.4625L8.58749 12.5458C8.20415 12.1625 8.20415 11.5875 8.58749 11.2042C8.97082 10.8208 9.59374 10.8208 9.92915 11.2042L11.175 12.45L14.3375 9.2875C14.7208 8.90417 15.2958 8.90417 15.6792 9.2875C16.0625 9.67083 16.0625 10.2458 15.6792 10.6292L11.8458 14.4625C11.6542 14.6542 11.4146 14.75 11.175 14.75Z" fill="#035A4B" />
                                    </svg>
                                  </span>
                                    </div>
                                    <div className="flex-grow-1 ms-4">
                                        <h4>Facilitate conversations</h4>
                                        <p>
                                            To facilitate conversations that will lead to the inclusion of students in overall decision making and policy implementation in the Commonwealth.
                                        </p>
                                    </div>
                                </div>
                                {/* End Icon Blocks */}
                            </div>
                            {/* End Col */}
                            <div className="col-md-6 col-lg-5">
                                {/* Icon Blocks */}
                                <div className="d-flex ps-lg-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="flex-shrink-0">
                              <span className="svg-icon text-primary">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4.85714 1H11.7364C12.0911 1 12.4343 1.12568 12.7051 1.35474L17.4687 5.38394C17.8057 5.66895 18 6.08788 18 6.5292V19.0833C18 20.8739 17.9796 21 16.1429 21H4.85714C3.02045 21 3 20.8739 3 19.0833V2.91667C3 1.12612 3.02045 1 4.85714 1ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z" fill="#035A4B" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M6.85714 3H14.7364C15.0911 3 15.4343 3.12568 15.7051 3.35474L20.4687 7.38394C20.8057 7.66895 21 8.08788 21 8.5292V21.0833C21 22.8739 20.9796 23 19.1429 23H6.85714C5.02045 23 5 22.8739 5 21.0833V4.91667C5 3.12612 5.02045 3 6.85714 3ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z" fill="#035A4B" />
                                </svg>
                              </span>
                                    </div>

                                    <div className="flex-grow-1 ms-4">
                                        <h4>Capacity development </h4>
                                        <p>
                                            To provide capacity development and leadership training
                                            opportunities for youth and Student leaders and other participants.
                                        </p>
                                    </div>
                                </div>
                                {/* End Icon Blocks */}
                            </div>
                            {/* End Col */}
                        </div>
                        {/* End Row */}
                    </div>
                </div>

            </div>

        </PageWrapper>
    )
}



export default About