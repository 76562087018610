import {PageWrapper} from "../layouts";
import {appImg} from "../config/config";
import {PagesHeader} from "../components";

const Address = () => {
    return (
        <PageWrapper>


            <PagesHeader mainText={'Our Address'} subText={''} bgImage={'address_bg'}/>




            <div className={'container '}>
                <div className="section-heading  wow fadeInDown text-center" data-wow-duration="1s" data-wow-delay="0.5s">
                <img src="assets/images/heading-line-dec.png" alt=""/>
                </div>
                <div className="row align-items-md-center mt-5">
                    <div className="col-md-5 d-none d-md-block">
                        <img className="img-fluid rounded-2" src="https://leadership.ng/wp-content/uploads/2022/11/President-Muhammadu-Buhari-jpg.webp" alt="Description"
                             style={{width:"400px",height:"500px"}}/>
                    </div>
                    {/* End Col */}
                    <div className="col-md-7">
                        {/* Blockquote */}
                        <figure className="pe-md-7">
                            <div className="mb-4">
                                <img className="avatar avatar-xl avatar-4x3" src={appImg.flag} alt="Image Description"  width={'20px'}/>
                            </div>
                            <blockquote className="blockquote blockquote-lg" style={{fontSize:'1.4rem'}}>
                                The African continent is blessed with a large youth population that can meet our labor shortages,
                                Therefore, we need to tap into this abundant human resource by providing our youth with
                                quality education that is relevant to their goals and meets the requirements of the labor market.
                            </blockquote>
                            <figcaption className="blockquote-footer">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 d-md-none">
                                        <img className="avatar avatar-circle" src={"https://leadership.ng/wp-content/uploads/2022/11/President-Muhammadu-Buhari-jpg.webp"} alt="Description" />
                                    </div>
                                    <div className="flex-grow-1 ms-3 ms-md-0">
                                        President Muhammadu Buhari
                                        <span className="blockquote-footer-source">President of Nigeria</span>
                                    </div>
                                </div>
                            </figcaption>
                        </figure>
                        {/* End Blockquote */}
                    </div>
                    {/* End Col */}
                </div>



                {/*  */}
                <div className={' mt-5  p-5'}>
                    <div className="row align-items-md-center">
                        <div className="col-md-7">
                            <figure className="pe-md-0">
                                <blockquote className="blockquote blockquote-lg" style={{fontSize:'1.4rem'}}>
                                    Youth revolution is changing Africa’s development without the
                                    continent recognizing nor appreciating youth as significant actors, yet their trajectories in socio-economic participation remains undisputed.
                                    Unfortunately, if continuously ignored or under- utilised, Nations will be the losers.<br/><br/>
                                    There is an urgent need to fully assimilate young people’s unique aptitudes, strengths and perspectives into the broader development paradigm.
                                    <br/>
                                    <br/>
                                    Young people are active drivers of change at the grassroots level of “our communities.
                                </blockquote>
                                <figcaption className="blockquote-footer">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 d-md-none">
                                            <img className="avatar avatar-circle" src={appImg.min_of_sport} alt="Image Description" />
                                        </div>
                                        <div className="flex-grow-1 ms-3 ms-md-0">
                                            Sunday Akin Dare
                                            <span className="blockquote-footer-source">Minister Youth and Sports</span>
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                            {/* End Blockquote */}
                        </div>
                        {/* End Col */}

                        <div className="col-md-5 d-none d-md-block">
                            <img className="img-fluid rounded-2" src={appImg.min_of_sport} alt="Description" style={{width:"400px",height:"500px"}} />
                        </div>
                        {/* End Col */}

                    </div>
                </div>


                {/*3rd*/}
                <div className="row align-items-md-center mt-5">
                    <div className="col-md-5 d-none d-md-block">
                        <img className="img-fluid rounded-2"
                             src={appImg.common_wealth_sec}
                             alt="Description"
                             style={{width:"400px",height:"500px"}}/>
                    </div>
                    {/* End Col */}
                    <div className="col-md-7">
                        {/* Blockquote */}
                        <figure className="pe-md-7">
                            <div className="mb-4">
                                <img className="avatar avatar-xl avatar-4x3" src={appImg.flag} alt="Image Description"  width={'20px'}/>
                            </div>
                            <blockquote className="blockquote blockquote-lg" style={{fontSize:'1.4rem'}}>
                                Young people are at the heart of the Commonwealth Charter. De- spite the many challenges they face, young people give so much towards every aspect of the social,
                                economic, political and cultural life of our 56 member countries.
                                <br/>
                                <br/>
                                The Commonwealth’s future success rests with them. So here to- day we commit to investing in and promoting their development and engagement at community, regional,
                                national and pan-Commonwealth levels throughout this year and beyond.
                            </blockquote>
                            <figcaption className="blockquote-footer">
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 d-md-none">
                                        <img className="avatar avatar-circle" src={appImg.common_wealth_sec}  alt="Image Description" />
                                    </div>
                                    <div className="flex-grow-1 ms-3 ms-md-0">
                                        Rt Hon Patricia Scotland KC
                                        <span className="blockquote-footer-source">Commonwealth Secretary-General</span>
                                    </div>
                                </div>
                            </figcaption>
                        </figure>
                        {/* End Blockquote */}
                    </div>
                    {/* End Col */}
                </div>



                <div className={' mt-5  p-5'}>
                    <div className="row align-items-md-center">
                        <div className="col-md-7">
                            <figure className="pe-md-0">
                                <blockquote className="blockquote blockquote-lg" style={{fontSize:'1.4rem'}}>
                                    Nigeria’s population comprises of millions of very vibrant young people.
                                    I want to pay tribute to Nigeria for its continued support for youth development.
                                    Nigeria’s commitment to youth development is outstanding.
                                    <br/>
                                    <br/>
                                    I look forward to the time that we would have this meeting in Nigeria
                                    we hope that it would be a seminar moment for the students association and the youth in Nigeria and across the region would also benefit.

                                    </blockquote>
                                <figcaption className="blockquote-footer">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 d-md-none">
                                            <img className="avatar avatar-circle" src={appImg.LayneRobinsonImg} alt="Image Description" />
                                        </div>
                                        <div className="flex-grow-1 ms-3 ms-md-0">
                                            Mr Layne Robinson
                                            <span className="blockquote-footer-source">
                                                Head of Social Policy Commonwealth Secretariat
                                            </span>
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                            {/* End Blockquote */}
                        </div>
                        {/* End Col */}

                        <div className="col-md-5 d-none d-md-block">
                            <img className="img-fluid rounded-2"
                                 src={appImg.LayneRobinsonImg} alt="Description" style={{width:"400px",height:"500px"}} />
                        </div>
                        {/* End Col */}

                    </div>
                </div>


            </div>
        </PageWrapper>
    )
}


export  default  Address