import {PageWrapper} from "../layouts";

const  MemberCountries = () => {
    return(
        <PageWrapper>
            <div className={'p-5'}></div>
            <div className={'p-5'}></div>


            <div className="w-lg-100 mx-auto mb-1 mb-sm-9">
                <h2 className="h1 text-center">MEMBER COUNTRIES </h2>
                <div className={'container mt-4'}>
                    <div className="row justify-content-center text-center">
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                 src="https://www.nationsonline.org/flags/botswana_flag.gif"
                                 alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALkAAAB7CAMAAAAfU9HRAAAAh1BMVEXOESYAaz/80RYAAADTJyT2vRgfdzrixRz/1herjg//1BbvxhX/2Bc5LwU+NAXOqxLXsxP9whjpwxTeuBPIphJnVglwXQpEOAa5mRAGBQCLdAy0lRCmig6SeQ0eGQMyKQUoIQQtejnZMSShcBGBawtgUAkaFQIODAFWRwhPQQeZfw1saBPCrBrLeiuKAAACRElEQVR4nO2Z65KaQBBGyUwuwwDCgqiIl91EcnH3/Z8vgi61imY3qa+7ltR3fllO1fSpsenuwSAghBBCCCGEEEIIIYQQQgghhJD/lI+CfJXcPPgkx923O8HdAy9HnuSCuwdGDBfa0MltL2hudnYnuLucuS+2Nim82P6C5lNr7XSM5q48mJdyiS545rZlhGfupp35VOzQxczjWWc+i6UCSJn7fN6Zz3OpfJEyd4uqM68WUukili1Le2QpFUDKPJ+czCe5UASUuTsnzuwzWXyxBoqIMq9X4RlNb16eL6xqUESUeTa3byPJQMUGZe6j9ZvE19F7yxbjff39Ve8q9LDyDqwtLi9fEW8KYHFHVkVv9n869h9rg+yn4HqebW6Kb1JsKLC5i3Y3xGewR/MEuod6U18Vr6GZ0oLv/r74OfD+leLnLoG5xUfbC/EtOlNaJMzTy8d0kwoM6RLmw266Hod51AzMmwgfRuAJzaqBeYUas16AN3erK0VxNYbaEie9777/lOBfAeDNoz65i7joZzD8KwC4efx80MtD1/RmeUr6PfzQ4eb+WFk2i1O7XxyLe/Puz9yn961o2XdNZ7qMuYc3I7S5C7tS8mK+8qb7Cv73Bdo8KoeXZJ9N2l8BHAls7vPDozmYr1x7vUZXF/TNorbhlUn8cL22Nfpm8QXL49ONhadHcKTgM5aHh79d+FeCD2OF5vrQXB+a60NzfWiuD831obk+NNeH5vrQXB+a60NzfWiuD831obk+NNeH5vrQXB+a6zNe899Gcn1wYQRcnQAAAABJRU5ErkJggg==" alt="Logo" />
                        </div>

                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHsAAAA9CAMAAAC6GOY8AAAACVBMVEUAh1H///8AeTUH1OstAAAAO0lEQVRYhe3NQREAAAjDsIF/0VjYl7tUQJOU7bRtu6xjs9lsNpvNZrPZbDabzWaz2Ww2m81ms9nsZ/YBj+QKumdlkJoAAAAASUVORK5CYII="}
                                 alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALkAAAB7CAMAAAAfU9HRAAAAY1BMVEX80RYAel7OESb+1hXKACcAfWBgZFDYAB/vqhvMACbpkh3+2BX91BbsnhzXSyP/3BTdZSLQJyb4whjqlh3aVyPnjR71uhjgbiD6zBbTOCXmiB7kgx/jfx/ieiD0thnSLiXbXSLvFGftAAABvUlEQVR4nO3YbW+DIBSGYQZsKwVr1bbWvv//Xzmr66u0WdZsOST3+WLkA14hxweCenuh3j8+9e/Ljox6pZAjR44cOXLkyJEjR44cOXLkyJEjR44cOXLkyJEjR44cOXLkfyl3qcrd4SldsrxMVO6mYfqMLlg+zsdpyovSlEWKcjfJVT55suhi5bbyylc2RXkZVCgTlLtZN/3scbuIlc+PLjNPRe7OpRehnT0srocEy+tsfKrqe/7qPJLVguU68974rk6q06v3meQ11/aw9NHP+OXhPmZkybVroh4zaga/qjC5dnYV7kUmrOwwY6TJj9t+eUsyZfQQIE+uXZFdm0xWRENdoLztmOryn7Znl/huJFGubbcN9RUWD84uEuWuCVdfCMNYkStf96beb9bpyHVvMsv+MUqmW1zTgdXGbdQRt4y3i0R5nbeRcgzxNtrbkPF1KnK7MMFX3Q2X05UPJp4u8uSuMEadl9nVypjoViRQvs13+8sq2/0u36Yh17vq5i607ZhdGt2ynwxO4pN9EvLI5XMq2fLDQo4cOXLkyJEjR44cOXLkyJEjR44cOXLkyJEjR44cOXLkyJEjR/5v8i96Tm8www0ESQAAAABJRU5ErkJggg=="
                                 alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALkAAAB7CAMAAAAfU9HRAAAAFVBMVEUftToAcsX///////z8//8AYLgArR2as/gFAAAAgklEQVR4nO3QCQkDABDEwOvrX3JdBLbMKAi5AwAAAAAAAPhT31X3XKW8p7ynvKe8p7ynvKe8p7ynvKe8p7ynvKe8p7ynvKe8p7ynvDdc/lo1/Py96j6r7rFKeU95T3lPeU95T3lPeU95T3lPeU95T3lPeU95T3lPeU95T3lPeW+3/AexD2JJPGpo+wAAAABJRU5ErkJggg=="
                                 alt="Logo" />
                        </div>
                        {/* End Col */}

                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                src={"https://www.nationsonline.org/flags/gabon_flag.gif"}
                                 alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                                 src={"https://www.nationsonline.org/flags/gambia_flag.gif"}
                               alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                               src={"https://www.nationsonline.org/flags/kenya_flag.gif"}
                                  alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                               src={"https://www.nationsonline.org/flags/swaziland_flag.gif"}
                                  alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                               src={"https://www.nationsonline.org/flags/lesotho_flag.gif"}
                                  alt="Logo" />
                        </div>
                        {/* End Col */}

                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                               src={"https://www.nationsonline.org/flags/malawi_flag.gif"}
                                  alt="Logo" />
                        </div>
                        {/* End Col */}
                        <div className="col-4 col-sm-3 col-md-2 py-3">
                            <img className="avatar avatar-lg avatar-4x3 avatar-centered"
                               src={"https://www.nationsonline.org/flags/mauritius_flag.gif"}
                                  alt="Logo" />
                        </div>
                        {/* End Col */}


                    </div>
                </div>




            </div>
        </PageWrapper>
    )
}

export  default  MemberCountries