import {PageWrapper} from "../layouts";
import {BackArrow, FrontArrow, PagesHeader, SectionHeader} from "../components";
import {Carousel} from "@trendyol-js/react-carousel";
import {appImg} from "../config/config";
import {Link} from "react-router-dom";

const Partner = () => {
    return(
        <PageWrapper>
            {/*<PagesHeader subText={''} mainText={'Our Partners'} bgImage={''}/>*/}
            <div className={'p-5'}></div>
            <div className={'p-5'}></div>


            <div className="w-lg-100 mx-auto mb-1 mb-sm-9">
                <h2 className="h1 text-center">Our Partners </h2>
                <div className={'container mt-4'}>
                    <div className="row justify-content-center text-center">
                        {appImg.partner_images.map((item) =>  <div className="col-md-3 col-sm-3 col-md-2 py-0">
                            <Link to={item.link_url} target={"_blank"}>
                                <img className="avatar avatar-lg avatar-4x3 avatar-centered" src={item.image_url} alt="Logo" />
                            </Link>

                        </div> ) }




                    </div>
                </div>




            </div>





        </PageWrapper>
    )
}

export default  Partner