const Service = () => {
    return(
        <>
            <div id="services" className="services section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                                <h4>WHAT IS CYSS <em> Innovation</em>  Hackathon?</h4>
                                <img src="assets/images/heading-line-dec.png" alt="" />
                                <p>
                                    CYSS Hackathon is an international initiative to provide youth and students with a platform to solve some of the pressing problems we face in our daily lives,
                                    and thus inculcate a culture of product innovation and a mindset of problem- solving.&nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service-item first-service">
                                <div className="icon" />
                                <h4>Collaborating</h4>
                                <p>
                                   Crowdsourcing intelligence  for improving governance.
                                </p>
                                <div className="text-button">
                                    {/*<a href="#">Read More <i className="fa fa-arrow-right" /></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="service-item second-service">
                                <div className="icon" />
                                <h4>Ingenious</h4>
                                <p>Harnessing creativity and expertise of youth, students and visionaries.</p>
                                <div className="text-button">
                                    {/*<a href="#">Read More <i className="fa fa-arrow-right" /></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="service-item third-service">
                                <div className="icon" />
                                <h4>Engaging</h4>
                                <p>Sparking regional level hackathons,fueling imagination.</p>
                                <div className="text-button">
                                    {/*<a href="#">Read More <i className="fa fa-arrow-right" /></a>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="service-item fourth-service">
                                <div className="icon" />
                                <h4>Futuristic</h4>
                                <p>Building funnel for CYSS Hackathon future campaigns</p>
                                <div className="text-button">
                                    {/*<a href="#">Read More <i className="fa fa-arrow-right" /></a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div id="aboutSection" className="container content-space-t-2 content-space-t-lg-3">*/}
            {/*    /!* Heading *!/*/}
            {/*    <div className="w-lg-75 text-center mx-auto mb-5 mb-sm-9">*/}
            {/*        <h2 className="h1">What we do?</h2>*/}
            {/*        <p>A flexible theme for modern SaaS businesses</p>*/}
            {/*    </div>*/}
            {/*    /!* End Heading *!/*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-md-4 mb-7">*/}
            {/*            /!* Icon Blocks *!/*/}
            {/*            <div className="text-center px-lg-3">*/}
            {/*                <span className="svg-icon svg-icon-lg text-primary mb-3">*/}
            {/*                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                    <path d="M15.6 5.59998L20.9 10.9C21.3 11.3 21.3 11.9 20.9 12.3L17.6 15.6L11.6 9.59998L15.6 5.59998ZM2.3 12.3L7.59999 17.6L11.6 13.6L5.59999 7.59998L2.3 10.9C1.9 11.3 1.9 11.9 2.3 12.3Z" fill="#035A4B" />*/}
            {/*                    <path opacity="0.3" d="M17.6 15.6L12.3 20.9C11.9 21.3 11.3 21.3 10.9 20.9L7.59998 17.6L13.6 11.6L17.6 15.6ZM10.9 2.3L5.59998 7.6L9.59998 11.6L15.6 5.6L12.3 2.3C11.9 1.9 11.3 1.9 10.9 2.3Z" fill="#035A4B" />*/}
            {/*                  </svg>*/}
            {/*                </span>*/}
            {/*                <h3>Industry-leading designs</h3>*/}
            {/*                <p>Achieve virtually any design and layout from within the one template.</p>*/}
            {/*            </div>*/}
            {/*            /!* End Icon Blocks *!/*/}
            {/*        </div>*/}
            {/*        /!* End Col *!/*/}
            {/*        <div className="col-md-4 mb-7">*/}
            {/*            /!* Icon Blocks *!/*/}
            {/*            <div className="text-center px-lg-3">*/}
            {/*                <span className="svg-icon svg-icon-lg text-primary mb-3">*/}
            {/*                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4.85714 1H11.7364C12.0911 1 12.4343 1.12568 12.7051 1.35474L17.4687 5.38394C17.8057 5.66895 18 6.08788 18 6.5292V19.0833C18 20.8739 17.9796 21 16.1429 21H4.85714C3.02045 21 3 20.8739 3 19.0833V2.91667C3 1.12612 3.02045 1 4.85714 1ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z" fill="#035A4B" />*/}
            {/*                    <path fillRule="evenodd" clipRule="evenodd" d="M6.85714 3H14.7364C15.0911 3 15.4343 3.12568 15.7051 3.35474L20.4687 7.38394C20.8057 7.66895 21 8.08788 21 8.5292V21.0833C21 22.8739 20.9796 23 19.1429 23H6.85714C5.02045 23 5 22.8739 5 21.0833V4.91667C5 3.12612 5.02045 3 6.85714 3ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z" fill="#035A4B" />*/}
            {/*                  </svg>*/}
            {/*                </span>*/}
            {/*                <h3>Learn from the docs</h3>*/}
            {/*                <p>Whether you're a startup or a global enterprise, learn how to integrate with Front.</p>*/}
            {/*            </div>*/}
            {/*            /!* End Icon Blocks *!/*/}
            {/*        </div>*/}
            {/*        /!* End Col *!/*/}
            {/*        <div className="col-md-4 mb-7">*/}
            {/*            /!* Icon Blocks *!/*/}
            {/*            <div className="text-center px-lg-3">*/}
            {/*                <span className="svg-icon svg-icon-lg text-primary mb-3">*/}
            {/*                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                    <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B" />*/}
            {/*                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B" />*/}
            {/*                  </svg>*/}
            {/*                </span>*/}
            {/*                <h3>Accelerate your business</h3>*/}
            {/*                <p>We help power millions of businesses to built and run smoothly.</p>*/}
            {/*            </div>*/}
            {/*            /!* End Icon Blocks *!/*/}
            {/*        </div>*/}
            {/*        /!* End Col *!/*/}
            {/*    </div>*/}
            {/*    /!* End Row *!/*/}
            {/*</div>*/}

        </>
    )
}


export default  Service