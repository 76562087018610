import {CircularProgress, PageWrapper, Pricing} from "../layouts";
import {Link} from "react-router-dom";
import {PagesHeader} from "../components";

const Sponsorship = () => {
    return (
        <PageWrapper>
            <div className={''}>
                <PagesHeader showContent={false}/>


                <div className="container content-space-b-2 content-space-b-lg-1 mt-5">
                    {/* Heading */}
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h2>Funding Category</h2>
                    </div>
                    {/* End Heading */}
                    <div className="row mb-7">
                        <CircularProgress
                            text={'Platinum'}
                            val={'50M'}
                            color={'#404143'}
                            color_2={"#404143"}
                            right={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 99.86665792594512 4.000092604777535 Z"}
                            left_={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 22.282985041299696 43.643406899377304"}
                        />
                        <CircularProgress
                            text={'Gold'}
                            val={'30M'}
                            color={'#46B3C8'}
                            color_2={"#f8fafd"}
                            right={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 99.86665792594512 4.000092604777535 Z"}
                            left_={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 22.282985041299696 43.643406899377304"}

                        />
                        <CircularProgress
                            text={'Sliver'}
                            val={'20M'}
                            color={'#4CD1B4'}
                            color_2={"#f8fafd"}
                            right={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 99.86665792594512 4.000092604777535 Z"}
                            left_={"M 99.98044737244527 4.000001991173164 A 96 96 0 0 1 118.11125277862686 194.27609730355135"}

                        />

                        <CircularProgress
                            text={'Bronze'}
                            val={'10M'}
                            color={'#F6D2A7'}
                            color_2={"#f8fafd"}
                            right={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 99.86665792594512  4.000092604777535 Z"}
                            left_={"M 99.98044737244527 4.000001991173164 A 96 96 0 0 1 197.11125277862686 90.27609730355135"}
                        />

                        <div className={"col-sm-0 col-lg-3 mb-7 mb-lg-4"}></div>
                        <CircularProgress
                            text={'Stand Only'}
                            val={'5M'}
                            color={'#F1698C'}
                            color_2={"#f8fafd"}
                            right={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 99.86665792594512 4.000092604777535 Z"}
                            left_={"M 99.98044737244527 4.000001991173164 A 96 96 0 0 1 135.23328649854963 10.69929718915354"}
                        />
                        <CircularProgress
                            text={'Others'}
                            val={'1.2M'}
                            color={'#F1698C'}
                            color_2={"#f8fafd"}
                            right={"M 99.98044737244527 4.000001991173164 A 96 96 0 1 1 99.86665792594512 4.000092604777535 Z"}
                            left_={"M 99.98044737244527 4.000001991173164 A 96 96 0 0 1 110.11125277862686 4.27609730355135"}
                        />
                        <div className={"col-sm-0 col-lg-3 mb-7 mb-lg-4"}></div>

                    </div>
                    {/* End Row */}


                    {/* Card Info */}
                    {/*<div className="text-center">*/}
                    {/*    <div className="card card-info-link card-sm">*/}
                    {/*        <div className="card-body">*/}
                    {/*            Prefer to start with the Trial version? <a className="card-link ms-2" href="#">Go*/}
                    {/*            here <span className="bi-chevron-right small ms-1"/></a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/* End Card Info */}
                </div>


                <div id="pricingSection" className="container">
                    {/* Heading */}
                    <div className="w-lg-75 text-center mx-auto mb-5 mb-sm-9">
                        <h2 className="h1">Sponsorship Benefits</h2>
                        <p>Details description of Sponsorship Benefits </p>
                    </div>
                    {/* End Heading */}
                    <div className="w-lg-100 mx-lg-auto ">
                        <div className="row align-items-sm-end gx-3 mb-5 mb-md-7">

                            <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                <Pricing
                                    icon={'text-dark'}
                                    name={'Platinum'}
                                    listItems={[
                                    'Sponsor’s Business Banner to be prominently displayed on the event platform',
                                    'Advert post featured on Summit Brochure',
                                    'Prominent name and logo placement on all printed materials related to event',
                                    'Prominent Sponsor name recognition in all press re- leases related to events',
                                    'Exhibition slot/pass with complimentary booth',
                                    '30 Seconds Video of Spon- sor’s products & services during the event',
                                    'Publication of Sponsors in a National Daily before and after the event',
                                    'Distribution of Sponsor’s Marketing Materials to participants at the event (pre-approved by project team)',
                                    'Sponsor’s logo to be dis- played on publicity materials before the event',
                                    'Appreciation post featuring Sponsor in a National Daily'
                                ]}/>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                <Pricing
                                    icon={'text-info'}
                                    name={'Gold'}
                                    listItems={[
                                        'Sponsor’s Business Banner to be prominently displayed on the event platform',
                                        'Advert post featured on Summit Brochure',
                                        'Prominent name and logo placement on all printed materials related to event',
                                        'Prominent Sponsor name recognition in all press re- leases related to events',
                                        'Exhibition slot/pass with complimentary booth',
                                        '30 Seconds Video of Spon- sor’s products & services during the event',
                                        'Publication of Sponsors in a National Daily before and after the event',
                                        'Distribution of Sponsor’s Marketing Materials to participants at the event (pre-approved by project team)'
                                    ]}/>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-3 mb-md-0">
                                <Pricing
                                    icon={'text-success'}
                                    name={'Silver'}
                                    listItems={[
                                        'Sponsor’s Business Banner to be prominently displayed on the event platform',
                                        'Advert post featured on Summit Brochure',
                                        'Prominent name and logo placement on all printed materials related to event',
                                        'Prominent Sponsor name recognition in all press re- leases related to events',
                                        'Exhibition slot/pass with complimentary booth'
                                    ]}/>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-3 mb-md-0 mt-3">
                                <Pricing
                                    icon={'text-warning'}
                                    name={'Bronze'}
                                    listItems={[
                                        'Sponsor’s Business Banner to be prominently displayed on the event platform',
                                        'Advert post featured on Summit Brochure'
                                    ]}/>
                            </div>

                            <div className="col-sm-6 col-md-4 mb-3 mb-md-0 mt-3">
                                <Pricing
                                    icon={'text-danger'}
                                    name={'Other'}
                                    listItems={[
                                        'Sponsor’s Business Banner to be prominently displayed on the event platform',
                                        'Advert post featured on Summit Brochure',
                                    ]}/>
                            </div>



                        </div>
                        {/* End Row */}






                        {/* Card Info */}
                        <div className="text-center aos-init" data-aos="fade-up">
                            <div className="card card-info-link card-sm mb-5 mb-md-0">
                                <div className="card-body">
                                    Need More Info? <Link className="card-link ms-2" to="#">Contact us <span
                                    className="bi-chevron-right small ms-1"/></Link>
                                </div>
                            </div>
                        </div>
                        {/* End Card Info */}
                    </div>
                </div>


            </div>
        </PageWrapper>
    )
}
export default Sponsorship