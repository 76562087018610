const PagesHeader = ({mainText,subText,bgImage,showContent=true}) => {
    return (
        <>
        {showContent  ?
            <div className={`position-relative bg-primary overflow-hidden ${bgImage}`}>


                        <div className="container position-relative zi-2 content-space-3 content-space-md-5">
                        <div className="w-md-75 w-xl-65 text-center mx-md-auto">
                        {/* Heading */}
                        <div className="mb-7">
                            <h1 className="display-4 text-white mb-3">{mainText}</h1>
                            <p className="lead text-white mb-4">{subText}</p>
                        </div>
                        {/* End Title & Description */}
                    </div>
                        </div>
            </div>
            :
            <div className={'overflow-hidden content-space-1 content-space-md-1 '}>
            </div>
        }

        </>
    )
}

export  default  PagesHeader