import {PageWrapper} from "../layouts";
import {Link} from "react-router-dom";

const Hackathon = () => {
    return (
        <PageWrapper>
            <>
                <div className="container" >
                    <header className="blog-header py-3" >
                        <div className="row flex-nowrap justify-content-between align-items-center">
                            <div className="col-4 pt-1">

                            </div>
                        </div>
                    </header>
                </div>

                <main className="container">
                    <div className="p-4 p-md-5 mb-4 text-white rounded  login_bg">
                        <div className="col-md-6 px-0">
                            <h1 className="display-4 text-white fst-italic">Commonwealth Youth and Students Summit for African Region -Hackathon 2023</h1>
                            <p className="lead my-3"></p>
                            {/*<p className="lead mb-0"><a href="#" className="text-white fw-bold">Continue reading...</a></p>*/}
                        </div>
                    </div>





                    <div className="row g-5">
                        <div className="col-md-8">
                            <div className={'py-4 card px-3'}>
                                <h3 className={'mb-2'}>What to Build</h3>
                                <div>Scalable applications anticipated from the participating developers should be built on top of flexible platforms,
                                    and must aim to provide innovative, safe and sustainable solutions in line stated themes.</div>
                                <div>Entrants are expected to build applications that ensure parties in youth and student organisations can have user-friendly access and
                                    take into consideration Smart integration of information, Multimodal accessibility services,
                                    Customized language optimization, with Sustainability for continuous improvements in place.

                                    Each solution should present a functional user experience to anyone, anywhere.
                                    This includes serving areas of low internet penetration and low smartphone adoption.</div>
                            </div>

                            <div className={'card px-3 py-4 mt-2'}>
                                <h3 className="pb-4 mb-2 fst-italic">Hackathon Outline </h3>
                                <table className={'table  table-striped table-responsive'}>
                                    <thead className={''}>
                                    <tr>
                                        <th></th>
                                        <th>Pre-Hack: 14 April - 28th April</th>
                                        <th>Hack & Pitch: 29th April- 9th May</th>
                                        <th>Post-Hack: 15th May</th>
                                    </tr>

                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><b>Objective</b></td>
                                        <td>The Online Pre-Hackathon Workshops are intended
                                            to be a primer for the Challenge. This is where
                                            the youth gets a chance
                                            to learn more about the Hackathon challenges, get inspired by the experts and network with like-minded innovators.
                                            Teams from various countries will be matched based on their skills levels and areas of interest. Each team will have 1– 5 members.
                                        </td>

                                        <td>The teams will be given an opportunity to focus on solution implementation, prototype ideation and development.</td>
                                        <td>The teams will receive guidance on how
                                            to further test their prototype and innovate around their service offering.</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <b>Problem owners, issue/ subject matter experts and/ or passionate
                                                “grassroots” / curious citizens</b>
                                        </td>
                                        <td>Need to be involved to ensure definition of relevant topics and themes for the hackathon. Only in this
                                            way actual problems of real value can be addressed.</td>
                                        <td>"Problem owners" or “issue experts” are crucial for inspiration and continual relevance and context of the problem/
                                            solutions being worked on.
                                        </td>
                                        <td>
                                            To allow for a “continued life” for the solutions and ideas proposed during the hackathon, Selected teams of students will work with CSA,
                                            Snake Nation and Open Seed team. (e.g. figuring as relevant partners proposing or enabling testing of the solution).
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><b>Students and researchers (e.g. Programmers, Gamers, Coders, topic related study fields, service designers)</b></td>
                                        <td>Can engage projects that inform the planning of the hackathon events.</td>
                                        <td>Participation, Ideation, Networking and Learning.</td>
                                        <td>Can pursue projects that test and develop concepts and prototypes further after the event. The aim is
                                            to start companies for the student teams that prove viable and have the entrepreneurial passion.</td>
                                    </tr>

                                    <tr>
                                        <td><b>Start-ups and creative industry</b></td>
                                        <td>Networking in terms of promotion inspiration and ensuring the recruitment of a critically thinking and creative crowd.</td>
                                        <td>Incorporating attention towards sustainability and continued economic life of projects.</td>
                                        <td>Can play a role in ensuring the continued work, development and incubation.</td>
                                    </tr>

                                    <tr>
                                        <td><b>Sponsors of various kind</b></td>
                                        <td>Think already early about making agreements with relevant collaborations.</td>
                                        <td>Making it possible to host an event with food, attractive venue, swag and maybe even a prize to win.</td>
                                        <td>Can play a role in ensuring the continued work, development and incubation. Supervision and mentoring</td>
                                    </tr>



                                    </tbody>
                                </table>
                            </div>


                            <div className={'py-4 px-3 mt-2'}>
                                <h5>Key Milestones:</h5>
                                <div className={'row gap-3 mt-3'}>
                                    <div className={'col-md-3 card py-4 text-center'}>
                                        <h5 className={'text-primary'}>Launch</h5>
                                        <h6>11th April</h6>
                                    </div>

                                    <div className={'col-md-3 card py-4 text-center'}>
                                        <h5 className={'text-primary'}>Pre Hack</h5>
                                        <h6>14th - 28th April</h6>
                                    </div>
                                    <div className={'col-md-3 card py-4 text-center'}>
                                        <h5 className={'text-primary'}>Hack & Pitch</h5>
                                        <h6>29th April- 9th May</h6>
                                    </div>

                                    <div className={'col-md-3 card py-4 text-center'}>
                                        <h5 className={'text-primary'}>Post Hack</h5>
                                        <h6>15th May</h6>
                                    </div>

                                </div>

                            </div>





                        </div>

                        <div className="col-md-4">
                            <div className="position-sticky" style={{top: "2rem"}}>
                                <div className="p-4 mb-3  rounded">
                                    <h4 className="fst-italic"></h4>
                                    <p className="mb-0">
                                    <span className={'card p-5'}>
                                        <b className={'text-primary  text-bold price_heading'}>Prices:</b>
                                        <ul style={{listStyleType:"disc"}}>
                                            <li className={'text-dark'}>1<sup>st</sup> price : <b className={'price_value'}>$10,000</b> </li>
                                            <li className={'text-dark'}>2<sup>nd</sup> price : <b className={'price_value'}>$5,000</b>  </li>
                                            <li className={'text-dark'}>3<sup>rd</sup> price : <b className={'price_value'}>$5,000</b>  </li>
                                        </ul>
                                    </span>
                                        <hr/>

                                        <span className={'card p-5'}>
                                        <b className={'text-primary text-bold price_heading'}>Dates:</b>
                                        <ul style={{listStyleType:"disc"}}>
                                            <li>Arrival 8th May 2023</li>
                                            <li>Departure 12th May 2023</li>
                                        </ul>
                                    </span>
                                        <hr/>
                                        <span className={'card p-5'}>
                                         <strong className={'text-primary text-bold price_heading'}>Venues:</strong>
                                         Abuja Continental Hotel, Central Business District, Abuja, Nigeria
                                     </span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                </main>

            </>
        </PageWrapper>
    )
}
export  default  Hackathon