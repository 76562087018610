const Pricing = ({listItems,name,icon}) => {
    return (
        <>
            <div className="card card-bordered shadow-md aos-init aos-animate" data-aos="fade-up"
                 data-aos-delay={100}>
                <div className="card-header text-center pb-0">
                                        <span className={`svg-icon svg-icon-sm ${icon} mb-3`}>
                                          <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.85"
                                                  d="M15.9999 25.6C21.3018 25.6 25.5999 21.302 25.5999 16C25.5999 10.6981 21.3018 6.40002 15.9999 6.40002C10.698 6.40002 6.3999 10.6981 6.3999 16C6.3999 21.302 10.698 25.6 15.9999 25.6Z"
                                                  fill="#377dff"/>
                                          </svg>
                                        </span>
                    <h4 className="card-title">{name}</h4>
                    {/*<h3 className="card-title">Free</h3>*/}
                </div>
                <div className="card-body">
                    <ul className="list-checked list-checked-primary">
                        {listItems.map((item,i=0)=> <li className="list-checked-item">{item}</li>
                        )}

                    </ul>
                </div>

                {/*<div className="card-footer pt-0">*/}
                {/*    <div className="d-grid">*/}
                {/*        <button type="button"*/}
                {/*                className="btn btn-primary btn-sm btn-transition">Create account*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </>
    )
}

export  default  Pricing