import {appImg} from "../config/config";
import {Link} from "react-router-dom";
import {appUrl} from "../index";

const Banner = () => {
    return (
        <>
            <div className="container-fluid content-space-2 content-space-lg-3 login_bg">
                <div className="row justify-content-lg-between align-items-lg-center">
                    <div className="col-lg-6 mb-9 mb-lg-0 px-4">
                        <div className="mb-3">
                            <h2 className="display-4 text-white">Commonwealth Youth and Students Summit for African Region -Hackathon 2023</h2>
                        </div>
                        <p className={'text-white'}>8th - 12th MAY 2023</p>
                        <div className="mt-4">
                            <Link className="btn btn-primary btn-transition px-5" to={appUrl.sign_up}>Sign Up</Link>
                        </div>
                    </div>
                    {/* End Col */}
                    <div className="col-lg-5 col-xl-5">
                        {/* SVG Element */}
                        <div className="position-relative mx-auto" style={{maxWidth: '28rem', minHeight: '30rem'}}>
                            <figure className="position-absolute top-0 end-0 zi-2 me-10 aos-init aos-animate" data-aos="fade-up">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 450 450" width={165} height={165}>
                                    <g>
                                        <defs>
                                            <path id="circleImgID2" d="M225,448.7L225,448.7C101.4,448.7,1.3,348.5,1.3,225l0,0C1.2,101.4,101.4,1.3,225,1.3l0,0 c123.6,0,223.7,100.2,223.7,223.7l0,0C448.7,348.6,348.5,448.7,225,448.7z" />
                                        </defs>
                                        <clipPath id="circleImgID1"><use xlinkHref="#circleImgID2" /></clipPath>
                                        <g clipPath="url(#circleImgID1)">
                                            <image width={450} height={450} xlinkHref={appImg.periscope} />
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                            <figure className="position-absolute top-0 start-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={300}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 335.2 335.2" width={120} height={120}>
                                    <circle fill="none" stroke="#377dff" strokeWidth={75} cx="167.6" cy="167.6" r="130.1" />
                                </svg>
                            </figure>
                            <figure className="d-none d-sm-block position-absolute top-0 start-0 mt-10 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 515 515" width={200} height={200}>
                                    <g>
                                        <defs>
                                            <path id="circleImgID4" d="M260,515h-5C114.2,515,0,400.8,0,260v-5C0,114.2,114.2,0,255,0h5c140.8,0,255,114.2,255,255v5 C515,400.9,400.8,515,260,515z" />
                                        </defs>
                                        <clipPath id="circleImgID3"><use xlinkHref="#circleImgID4" /> </clipPath>
                                        <g clipPath="url(#circleImgID3)">
                                            <image width={515} height={515} xlinkHref={appImg.logo} transform="matrix(1 0 0 1 1.639390e-02 2.880859e-02)" />
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                            <figure className="position-absolute top-0 end-0 aos-init aos-animate" style={{marginTop: '11rem', marginRight: '13rem'}} data-aos="fade-up" data-aos-delay={250}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 67 67" width={25} height={25}>
                                    <circle fill="#00C9A7" cx="33.5" cy="33.5" r="33.5" />
                                </svg>
                            </figure>

                            <figure className="position-absolute top-0 end-0 me-3 aos-init aos-animate" style={{marginTop: '8rem'}} data-aos="fade-up" data-aos-delay={350}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 141 141" width={50} height={50}>
                                    <circle fill="#FFC107" cx="70.5" cy="70.5" r="70.5" />
                                </svg>
                            </figure>

                            <figure className="position-absolute bottom-0 end-0 aos-init aos-animate" data-aos="fade-up" data-aos-delay={400}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 770.4 770.4" width={280} height={280}>
                                    <g>
                                        <defs>
                                            <path id="circleImgID6" d="M385.2,770.4L385.2,770.4c212.7,0,385.2-172.5,385.2-385.2l0,0C770.4,172.5,597.9,0,385.2,0l0,0 C172.5,0,0,172.5,0,385.2l0,0C0,597.9,172.4,770.4,385.2,770.4z" />
                                        </defs>
                                        <clipPath id="circleImgID5"> <use xlinkHref="#circleImgID6" /></clipPath>
                                        <g clipPath="url(#circleImgID5)">
                                            <image width={900} height={900}
                                                   xlinkHref={appImg.fed_minis} transform="matrix(1 0 0 1 -64.8123 -64.8055)" />
                                        </g>
                                    </g>
                                </svg>
                            </figure>
                        </div>
                        {/* End SVG Element */}
                    </div>
                    {/* End Col */}
                </div>
                {/* End Row */}
            </div>


            {/*<div className="container-fluid position-relative zi-2 content-space-3 content-space-md-5 login_bg">*/}
            {/*    <div className="w-md-75 w-xl-65 text-center mx-md-auto">*/}
            {/*        <div className="mb-7">*/}
            {/*            <h1 className="display-4 text-white mb-4">Commonwealth Youth and Students Summit for African Region -Hackathon 2023</h1>*/}
            {/*            <p className="lead text-white mb-4">8th - 12th MAY 2023</p>*/}
            {/*        </div>*/}


            {/*        <div className="d-grid d-sm-flex justify-content-center gap-2">*/}
            {/*            <Link className="btn btn-primary btn-transition px-6" to={appUrl.sign_up}>Sign Up</Link>*/}
            {/*            <a className="btn btn-outline-info" href="#">Get in touch <i*/}
            {/*                className="bi-chevron-right small ms-1"></i></a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
}


export default  Banner