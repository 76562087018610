import logo from './logo.svg';
import './App.css';
import {About, Banner, Clients, Newsletter, PageWrapper, Pricing, Service, TopNavbar} from "./layouts";
import {appImg, appStr} from "./config/config";
import {BackArrow, FrontArrow, SectionHeader} from "./components";
import {Link} from "react-router-dom";
import { Carousel,ScrollingCarousel } from '@trendyol-js/react-carousel';
import {appUrl} from "./index";

const App = () => {
    return (<PageWrapper>
            <div>
                {/*Preloader */}
                {/*<TopNavbar/>*/}
                {/* AuthModal   */}
                <Banner/>
                <Service/>


                {/*<About/>*/}

                <div className="container content-space-2 content-space-lg-3">
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <h2 className="h1">Upcoming Event(s)</h2>
                        <p>checkout our upcoming events</p>
                    </div>


                    <div className="row gx-3 mb-5 mb-md-9">
                        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
                            <Link className="card card-transition h-100" to={appUrl.cyss_hackathon_2023}>
                                <img className="card-img-top" src={appImg.cyss}
                                     alt="Image Description"/>
                                    <div className="card-body">
                                        <span className="card-subtitle text-primary">{appStr.app_name}</span>
                                        <h5 className="card-text lh-base">
                                            The CYSS Hackathon promises to be extremely effective
                                            in promoting innovative out-of-thebox...
                                        </h5>
                                    </div>
                            </Link>

                        </div>


                    </div>

                </div>








            </div>
        </PageWrapper>
    )

}

export default App;
