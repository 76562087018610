import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {Address, EventDetails, MemberCountries, Partner, SignUp, Sponsorship} from "./pages";
import {About} from "./layouts";
import Hackathon from "./pages/Hackathon";


export const appUrl = {
    address : '/address',
    home : '/',
    about_cyss : '/about-cyss',
    sponsorship : '/our-sponsor',
    partners : '/our-partners',
    members_country : '/member-countries',
    cyss_hackathon_2023 : '/cyss-hackathon-2023',
    sign_up : "/sign-up"
}

export const routesData = [
    // {
    //     name : 'Home',
    //     url : appUrl.home
    // },
    {
        name : 'Address',
        url : appUrl.address
    },

    {
        name : 'About CYSS',
        url : appUrl.about_cyss
    },
    {
        name : 'CYSS Hackathon 2023 ',
        url : appUrl.cyss_hackathon_2023
    },
    {
        name : 'Member Countries ',
        url : appUrl.members_country
    },
    {
        name : 'Sponsorship ',
        url : appUrl.sponsorship
    },
    {
        name : 'Our Partners ',
        url : appUrl.partners
    },

]


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
      path:appUrl.sign_up,
      element:<SignUp/>
    },
    {
        path : appUrl.address,
        element:<Address/>
    },
    {
        path : appUrl.about_cyss,
        element:<About/>
    },
    {
        path:appUrl.sponsorship,
        element:<Sponsorship/>
    },
    {
        path : appUrl.cyss_hackathon_2023,
        element:<Hackathon/>
    },
    {
        path : appUrl.members_country,
        element:<MemberCountries/>
    },

    {
        path: appUrl.partners,
        element: <Partner/>
    },
    {
        path: 'events/:id',
        element: <EventDetails/>
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
